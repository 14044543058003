/* eslint-disable max-len */
import React, { FC, PropsWithChildren, ReactNode } from "react";
import { Col, Navbar, Row } from "react-bootstrap";
import styled from "styled-components";
import MyNotifications from "./MyNotifications";

interface NavbarProps {
  pageTitle?: ReactNode;
  additionalContent?: ReactNode;
}

const ChildrenAndNotifications = styled.div`
  display: flex;
  justify-content: end;
  gap: 1rem;
`;

const TitleAndAdditionalContentWrapper = styled.div`
  display: flex;
  justify-content: start;
  gap: 1rem;
`;

const FullRow = styled(Row)`
  width: 100%;
`;

const AppNavbar: FC<PropsWithChildren<NavbarProps>> = ({
  children,
  pageTitle,
  additionalContent = null,
}) => {
  return (
    <>
      <Navbar
        id="header-nav"
        expand="lg"
        className="bg-white sticky-top border-bottom p-3 justify-content-lg-between ls-1"
      >
        <FullRow>
          <Col md={1}>
            <Navbar.Brand className="text-capitalize ls-1 fw-bold fs-3 py-lg-3">
              {pageTitle}
            </Navbar.Brand>
          </Col>
          <Col>
            <TitleAndAdditionalContentWrapper className="d-flex align-items-center my-2 my-lg-0">
              {additionalContent}
            </TitleAndAdditionalContentWrapper>
          </Col>
          <Col>
            <ChildrenAndNotifications>
              <div className="d-flex align-items-center my-2 my-lg-0">{children}</div>
              <MyNotifications />
            </ChildrenAndNotifications>
          </Col>
        </FullRow>
      </Navbar>
    </>
  );
};

export default AppNavbar;
