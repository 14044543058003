import React, { FC, lazy, Suspense } from "react";
import { gql } from "@apollo/client";
import { Navigate, Route, Routes } from "react-router-dom";

import config from "../../../config";
import LoadingPlaceholder from "./LoadingPlaceholder";
import RegistrationRoutes from "./RegistrationRoutes";
import VerificationBoundary from "./VerificationBoundary";
import MainSection from "./MainSection";
import { ResetPasswordForm } from "../../pages/ResetPassword";
import CreditBoundary from "../CreditBoundary";
import { JurisdictionProvider } from "@taxscribe/ui";
import OrganizationWrapper from "../OrganizationWrapper";
import styled from "styled-components";
// eslint-disable-next-line max-len
import MessageDrawerContextProvider from "../MessageDrawer/MessageDrawerContextProvider";
import MessageDrawer from "../MessageDrawer";
import { SSOAuthBoundary } from "@reasongcp/react-fire-sub";
import { useApolloClientWithAuth } from "@taxscribe/apollo-helpers";
import {
  manageBilling,
  viewForms,
  viewMailings,
  manageUsers,
} from "../../../constants/rolePermissions";
import RestrictedRoutes from "./RestrictedRoutes";
import QBRoutes from "./QBRoutes";

const MyOrganizations = lazy(() => import("../../pages/MyOrganizations"));
const NotificationPreferences = lazy(
  () => import("../../pages/NotificationPreferences"),
);
const EditUser = lazy(() => import("../../pages/Users/Edit"));
const Billing = lazy(() => import("../../pages/Billing"));
const Login = lazy(() => import("../../pages/Login"));
const Users = lazy(() => import("../../pages/Users"));
const SidebarNav = lazy(() => import("../SidebarNav"));
const NewUser = lazy(() => import("../../pages/Users/New"));
const Forms = lazy(() => import("../../pages/Forms"));
const NewForm = lazy(() => import("../../pages/Forms/New"));
const ClientForms = lazy(() => import("../../pages/ClientForms"));
const ClientLocationForms = lazy(
  () => import("../../pages/ClientLocationForms"),
);
const Locations = lazy(() => import("../../pages/Locations"));
const LoginRedirect = lazy(() => import("./LoginRedirect"));
const UpdateUser = lazy(() => import("../../pages/UpdateUser"));
const UpdateOrganization = lazy(() => import("../../pages/UpdateOrganization"));
const MyForms = lazy(() => import("../../pages/MyForms"));
const Completed = lazy(() => import("../../pages/Form/Completed"));
const Mailings = lazy(() => import("../../pages/Mailings"));
const Clients = lazy(() => import("../../pages/Clients"));
const FormSwitch = lazy(() => import("../../pages/Form"));

const JurisdictionRoutes: FC = () => {
  return (
    <JurisdictionProvider>
      <RestrictedRoutes
        roles={viewForms}
        redirectTo="/"
      >
        <Route
          path="/form/:formType/new-for/:accountNumberPrimary/*"
          element={
            <CreditBoundary>
              <FormSwitch />
            </CreditBoundary>
          }
        />
        <Route
          path="/form/:formType/clone/:baseFormUuid/*"
          element={
            <CreditBoundary>
              <FormSwitch />
            </CreditBoundary>
          }
        />
        <Route
          path="/form/:formType/new/*"
          element={
            <CreditBoundary>
              <FormSwitch />
            </CreditBoundary>
          }
        />
        <Route
          element={<Completed />}
          path="/form/:formType/:formUuid/complete"
        />
        <Route
          path="/form/:formType/:formUuid/*"
          element={<FormSwitch />}
        />
      </RestrictedRoutes>
    </JurisdictionProvider>
  );
};

const ContentAndFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`;

const HomeRedirect: FC = () => <Navigate to="/forms" />;

/* eslint-disable max-len */
const AppRoutes: FC = () => {
  const firesubClient = useApolloClientWithAuth(config.fireSubApi);
  return (
    <Routes>
      <Route
        path="/login"
        element={<Login />}
      />
      <Route
        path="/reset-password"
        element={<ResetPasswordForm />}
      />
      <Route
        path="/register/*"
        element={<RegistrationRoutes />}
      />
      <Route
        path="/*"
        element={
          <SSOAuthBoundary
            gql={gql}
            client={firesubClient}
            InvalidPlaceHolder={LoginRedirect}
            LoadingPlaceHolder={LoadingPlaceholder}
          >
            <VerificationBoundary>
              <OrganizationWrapper>
                <SidebarNav />
                <MainSection>
                  <ContentAndFooterWrapper>
                    <Suspense fallback={<LoadingPlaceholder />}>
                      <MessageDrawerContextProvider>
                        <div>
                          <Routes>
                            <Route
                              path="/home"
                              element={<HomeRedirect />}
                            />
                            <Route
                              path="/update-organization"
                              element={<UpdateOrganization />}
                            />
                            <Route
                              path="/update-user"
                              element={<UpdateUser />}
                            />
                            <Route
                              path="/clients"
                              element={<Clients />}
                            />
                            <Route
                              path="/client-forms"
                              element={<ClientForms />}
                            />
                            <Route
                              path="/client-location-forms"
                              element={<ClientLocationForms />}
                            />
                            <Route
                              path="/locations"
                              element={<Locations />}
                            />
                            <Route
                              path="/my-organizations"
                              element={<MyOrganizations />}
                            />
                            <Route
                              path="/notification-preferences"
                              element={<NotificationPreferences />}
                            />
                            <Route
                              path="/qb/*"
                              element={<QBRoutes />}
                            />
                            <Route
                              path="/:state/:jurisdictionName/:jurisdictionType/*"
                              element={<JurisdictionRoutes />}
                            />
                          </Routes>
                          <RestrictedRoutes roles={viewMailings}>
                            <Route
                              path="/mailings"
                              element={<Mailings />}
                            />
                          </RestrictedRoutes>
                          <RestrictedRoutes roles={manageBilling}>
                            <Route
                              path="/billing"
                              element={<Billing />}
                            />
                          </RestrictedRoutes>
                          <RestrictedRoutes roles={manageUsers}>
                            <Route
                              path="/users"
                              element={<Users />}
                            />
                            <Route
                              path="/users/new"
                              element={<NewUser />}
                            />
                            <Route
                              path="/users/:uid"
                              element={<EditUser />}
                            />
                          </RestrictedRoutes>
                          <RestrictedRoutes roles={viewForms}>
                            <Route
                              path="/tsb-forms"
                              element={<MyForms />}
                            />
                            <Route
                              path="/forms"
                              element={<Forms />}
                            />
                            <Route
                              path="/forms/new"
                              element={<NewForm />}
                            />
                          </RestrictedRoutes>
                        </div>
                        <MessageDrawer />
                      </MessageDrawerContextProvider>
                    </Suspense>
                  </ContentAndFooterWrapper>
                </MainSection>
              </OrganizationWrapper>
            </VerificationBoundary>
          </SSOAuthBoundary>
        }
      />
      <Route
        path="/"
        element={
          <Navigate
            replace
            to="/home"
          />
        }
      />
    </Routes>
  );
};
/* eslint-enable max-len */

export default AppRoutes;
