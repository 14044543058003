import { createContext } from "react";
import { KnownStage } from "../../../config";

export type KnownFlags = "ELECTRONIC_SIGNATURE" | "QB_INTEGRATION";

export type EnvironmentFlags = Record<KnownStage, KnownFlags[]>;

export const FeatureFlagsContext = createContext<EnvironmentFlags>({
  "qa": [],
  "uat": [],
  "production": [],
  "development": [],
});
