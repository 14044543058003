import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback } from "react";
import styled from "styled-components";
import colors from "../../../constants/colors";
import { Notification } from "../../../types/notifications";
import WhenInView from "./WhenInView";

export interface NotificationDetailsProps {
  notification: Notification;
  onVisible: (id: number) => void;
}

interface NotificationWrapperProps {
  viewed: boolean;
}
const getViewedColor = ({ viewed }: NotificationWrapperProps) => {
  if (viewed) {
    return colors.tsBlueGrayDark;
  }
  return colors.tsPurpleAlt;
};

const NotificationWrapper = styled.div<NotificationWrapperProps>`
  display: flex;
  justify-content: start;
  gap: 1rem;

  && svg {
    color: ${getViewedColor};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StatusChange: FC<Pick<NotificationDetailsProps, "notification">> = ({
  notification,
}) => {
  const { accountNumberPrimary, businessName, viewed } = notification;
  const bestDisplayName = accountNumberPrimary || businessName;
  const status = notification.metadata?.status || "Updated";
  return (
    <NotificationWrapper viewed={viewed}>
      <>
        <IconWrapper>
          <FontAwesomeIcon icon={faCircleExclamation} />
        </IconWrapper>
        Form {bestDisplayName} has been {status}
      </>
    </NotificationWrapper>
  );
};

const NotificationDetails: FC<NotificationDetailsProps> = ({
  onVisible,
  notification,
}) => {
  const { id, viewed } = notification;
  const handleView = useCallback(() => {
    if (!viewed) {
      onVisible(id);
    }
  }, [id, onVisible, viewed]);

  return (
    <div>
      <StatusChange notification={notification} />
      <WhenInView onVisibilityChange={handleView} />
    </div>
  );
};

export default NotificationDetails;
