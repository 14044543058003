import React, { FC, PropsWithChildren, useMemo } from "react";
import { FeatureFlagsContext, KnownFlags } from ".";
import { KnownStage } from "../../../config";

const FeatureFlagsContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  // Someday this will become a GQL call.
  const state = useMemo(() => {
    const flagsByEnv: Record<KnownStage, KnownFlags[]> = {
      "qa": ["ELECTRONIC_SIGNATURE", "QB_INTEGRATION"],
      "uat": [],
      "production": [],
      "development": ["ELECTRONIC_SIGNATURE", "QB_INTEGRATION"],
    };

    return flagsByEnv;
  }, []);

  return (
    <FeatureFlagsContext.Provider value={state}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export default FeatureFlagsContextProvider;
